.container {
    max-width: 1050px;
    margin: 50px auto;
}

.projects-container {
    text-align: center;
}

.projects-header {
  padding: 0 30px;
}

.projects-header > h1 {
    font-size: 32px;
    display: inline;
    border-bottom: 2px solid #000;
}

.projects-header > p {
    font-size: 18px;
}

.about {
  max-width: 600px;
  margin: 0 auto 100px auto;
  padding: 30px;
}

.about p {
  font-size: 20px;
  margin-bottom: 50px;
}

.about h1 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: normal;
}

.profile-photo {
  clip-path: circle(38%);
  width: 100%;
  margin-bottom: 50px;
}

.profile-photo img {
  box-shadow: none;
  max-width: 400px;
  width: 100%;
  margin: auto;
}

@media (max-width: 600px) {
  .profile-photo img {
    width: 70%;
  }
}

.btn {
  background-color: #fff;
  border-color: #eee;
  border-style: solid;
  border-width: 0 0 4px 0;
  box-shadow: 0 2px 8px #ccc;
  padding: 12px 24px;
  font-size: 18px;
  color: #555;
  margin: 5px;
  transition: all .2s ease;
}

.btn:hover {
  background-color: #f5f5f5;
  border-color: #e5e5e5;
  color: #333;
}

.link-btn {
  text-decoration: none;
}

@media(max-width: 350px) {
  .btn-group .btn {
    font-size: 15px;
  }
}
