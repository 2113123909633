.project {
    text-align: center;
    border-radius: 3px;
    padding: 10px 30px 75px 30px;
}

.project:nth-child(even) {
  background-color: #f6f6f6;
  box-shadow: 0 0 25px #e8e8e8;
}

.project:nth-child(even) img {
  box-shadow: 0 5px 15px #ddd;
}

.project-header {
  margin: 30px auto;
}

.project-header h1 {
    margin: 0;
    font-size: 32px;
}

.project-header span {
    font-size: 20px;
    color: #555;
}

.project-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    max-width: 1100px;
    margin: auto;
}

.project-body > div {
    margin-top: 50px;
}

img {
    width: 100%;
    box-shadow: 0 5px 15px #aaa;
    border-radius: 3px;
}

img.no-shadow {
    box-shadow: none !important;
}

video {
    box-shadow: 0 5px 15px #aaa;
    border-radius: 3px;
}

.project-content {
    padding: 0;
    max-width: 430px;
}

.project-content p {
   font-size: 18px;
   text-align: justify;
   margin-bottom: 30px;
}

.project-image {
  max-width: 350px;
  padding: 0 50px;
  flex-grow: 1;
}

@media(min-width: 400px) {
  .project-content {
    padding: 20px;
  }
}

@media(max-width: 1000px) {
  .project-body {
    justify-content: center;
  }
  .project-image {
    padding: 0 20px;
    max-width: 450px;
  }
  .project-content {
    max-width: 500px;
  }
}

.btn.btn-github {
  display: inline-flex;
  align-items: center;
}

.logo {
  box-shadow: none;
  width: 60px;
  margin-left: 2px;
}
